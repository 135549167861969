import React from "react";
import { Carousel } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { useMediaQuery, useTheme } from "@mui/material";

const BootstrapCarousel = ({ images, height = "650px", smHeight = "500px" }) => {
  const theme = useTheme();
  const matchesSM = useMediaQuery(theme.breakpoints.down("sm"));
  return (
    <Carousel
      indicators={images?.length === 1 ? false : true}
      controls={images?.length === 1 ? false : true}
    >
      {images?.map(
        ({ src, mobileSrc, name = "Rockhouse Foundation", title = null, text = null }, i) => (
          <Carousel.Item
            interval={4000}
            key={i}
            style={{
              textAlign: "center",
            }}
          >
            {matchesSM ? (
              <img
                loading="lazy"
                className="w-full object-cover h-full"
                src={mobileSrc}
                alt={name}
              />
            ) : (
              <img loading="lazy" className="w-full object-contain   h-full" src={src} alt={name} />
            )}

            {title !== null && (
              <Carousel.Caption>
                <h3 className="text-3xl drop-shadow-2xl">{title}</h3>
                {text !== null && <p className="text-sm shadow-indigo-500/50">{text}</p>}
              </Carousel.Caption>
            )}
          </Carousel.Item>
        )
      )}
    </Carousel>
  );
};

export default BootstrapCarousel;

export const BootstrapCarousel2 = ({ images }) => {
  return (
    <Carousel
      indicators={images?.length === 1 ? false : true}
      controls={images?.length === 1 ? false : true}
    >
      {/* </Box> */}
      {images?.map(({ src, name, mobileSrc = "", title = null, text = null }, i) => (
        <Carousel.Item
          interval={4000}
          key={i}
          style={{
            textAlign: "center",
          }}
        >
          <div
            className={`relative w-full 
                 xs:max-h-[400px] sm:max-h-[650px]
              `}
          >
            <img
              className={`sm:h-[450px] w-full object-cover object-center h-[350px] `}
              src={src}
              alt={name}
            />
          </div>
          {title !== null && (
            <Carousel.Caption>
              <h3 className="text-3xl drop-shadow-2xl">{title}</h3>
              {text !== null && <p className="text-sm shadow-indigo-500/50">{text}</p>}
            </Carousel.Caption>
          )}
        </Carousel.Item>
      ))}
    </Carousel>
  );
};
