import { Box, Button, CircularProgress, Stack, Typography } from "@mui/material";
import React from "react";
import Pages from "../components/features/Pages";
import Seo from "../components/layout/Seo";

import PreviousEvents from "../components/features/events/PreviousEvents";
import CurrentEvents from "../components/features/events/CurrentEvents";
import Raffle from "../components/features/events/raffle/Raffle";
import { isEmpty } from "lodash";
import LoadingSkeleton from "../components/features/events/LoadingSkeleton";

export const RAFFLE_URL = `https://www.gofundme.com/f/sav-inclusive-primary-school-campaign-2025?attribution_id=sl:c9827d4e-4088-4ab3-9b72-2d5d226ccc82&lang=en_GB&utm_campaign=man_sharesheet_dash&utm_content=amp13_t1-amp14_t2&utm_medium=customer&utm_source=copy_link`;

const Events = () => {
  const [raffle, setRaffle] = React.useState(null);
  // const headerImage = `https://res.cloudinary.com/dfwvu4gct/image/upload/fl_preserve_transparency/v1665598688/RHF/RHF_Supporters_1920x823_A_Events_j8euyu.jpg?_s=public-apps`;
  // const headerImageMobile = `https://res.cloudinary.com/dfwvu4gct/image/upload/fl_preserve_transparency/v1665598688/RHF/RHF_Supporters_1920x823_A_Events_j8euyu.jpg?_s=public-apps`;

  const fetchRaffle = async () => {
    const response = await fetch(`${process.env.REACT_APP_API_URL}/rhf/raffles?year=2025`);
    const data = await response.json();
    console.log(data);
    setRaffle(data?.[0]);
    return data;
  };

  React.useEffect(() => {
    fetchRaffle();
  }, []);
  return (
    <>
      <Seo title="Events" />
      {isEmpty(raffle) ? (
        <div className="flex justify-center items-center h-screen">
          <CircularProgress />
        </div>
      ) : (
        <Pages
          title={raffle?.title}
          imageUrl={`${raffle?.desktop_image}`}
          mobileSrc={`${raffle?.mobile_image}`}
          alt="Rockhouse Foundation Raffle 2025"
          subtitle="For each $50 donated to our Summer Building Campaign you earn and entry in the raffle"
        >
          <div className="text-center">
            <Button
              variant="contained"
              href={RAFFLE_URL}
              disableElevation
              sx={{
                color: "#fff",
                fontWeight: "bold",
                borderRadius: "25px",
                mb: 2,
              }}
              className="hover:text-white"
            >
              Donate for a chance to win
            </Button>
          </div>
          <Box className="my-4 container">
            <div className="space-y-6 container mx-auto pb-6">
              {!isEmpty(raffle?.items) ? (
                <Stack
                  component="a"
                  href={RAFFLE_URL}
                  className="no-underline bg-orange-50 rounded-lg pb-3  max-w-5xl mx-auto"
                  justifyContent="center"
                  alignItems="center"
                  rowGap={3}
                >
                  <Raffle raffle={raffle} />
                  <Button
                    variant="contained"
                    href={RAFFLE_URL}
                    disableElevation
                    sx={{
                      color: "#fff",
                      fontWeight: "bold",
                      borderRadius: "25px",
                    }}
                    className="hover:text-white"
                  >
                    Donate for a chance to win
                  </Button>
                  <Button
                    variant="contained"
                    href="https://rockhousefoundation.networkforgood.com"
                    // size="'small"
                    disableElevation
                    sx={{
                      color: "#fff",
                      fontWeight: "bold",
                      borderRadius: "25px",
                    }}
                    className="hover:text-white"
                  >
                    For Amex donations click here
                  </Button>
                  <div className="text-center p-2 max-w-4xl mx-auto">
                    <Typography
                      variant="body1"
                      className="text-center"
                      color="textSecondary"
                      sx={{
                        fontSize: "1.1rem",
                      }}
                    >
                      The Raffle will be drawn on Saturday June 14th at our next Dinner On The Beach
                      to be held at Skylark Negril Beach Resort - details to follow.
                      <br /> Winners will be published on June 15th with instagram posts to{" "}
                      <a
                        href="https://www.instagram.com/rockhousefoundation/"
                        className="underline"
                      >
                        @rockhousefoundation
                      </a>
                      ,{" "}
                      <a href="https://www.instagram.com/rockhousehotel/" className="underline">
                        @rockhousehotel
                      </a>{" "}
                      and{" "}
                      <a href="https://www.instagram.com/skylarknegril/" className="underline">
                        @skylarknegril
                      </a>{" "}
                      and will also be published on this web page
                    </Typography>
                  </div>
                </Stack>
              ) : null}
              <CurrentEvents />
            </div>
          </Box>

          <div className="container my-4">
            <PreviousEvents />
          </div>
        </Pages>
      )}
    </>
  );
};

export default Events;
