import React from "react";

function RaffleItem({ image, title, description }) {
  return (
    <div className="grid md:grid-cols-2 gap-4 items-center pt-8 md:pb-4">
      <img src={image} alt="Rockhouse Foundation Raffle" className="w-full object-contain" />
      <div className="px-2">
        <h2 className="font-bold text-blue-900">{title}</h2>
        <p className="text-md md:text-xl text-blue-900">{description}</p>
      </div>
    </div>
  );
}

export default RaffleItem;
