import React from "react";

function LoadingSkeleton() {
  return (
    <div className="w-full mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 animate-pulse flex flex-col space-y-4">
      <div className="h-98 bg-slate-300 " />
      <div>
        <div className="h-8 bg-slate-300  w-full" />
        <div className="h-8 bg-slate-300  w-full" />
      </div>
    </div>
  );
}

export default LoadingSkeleton;
