import React from "react";
import RaffleItem from "./RaffleItem";
// http://localhost:8000/api/rhf/raffles?year=2025

function Raffle({ raffle }) {
  return (
    <div className="mx-auto divide-y-8 divide-pink-400 space-y-4  border-pink-400 border-y-8 pb-">
      {raffle?.items?.map((item) => (
        <RaffleItem key={item.id} {...item} />
      ))}
    </div>
  );
}

export default Raffle;
