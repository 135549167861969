import { useTheme } from "@emotion/react";
import { PlayCircleFilledOutlined } from "@mui/icons-material";
import { Skeleton, useMediaQuery } from "@mui/material";
import useIntersectionObserver from "@react-hook/intersection-observer";
import React, { useState, useRef } from "react";
import ReactPlayer from "react-player";

function VideoPlayer() {
  const videoRef = useRef(null);
  const [isFullscreen, setIsFullscreen] = useState(false);

  const toggleFullscreen = () => {
    if (videoRef.current) {
      if (!document.fullscreenElement) {
        videoRef.current.requestFullscreen();
      } else {
        document.exitFullscreen();
      }
      setIsFullscreen(!isFullscreen);
    }
  };

  return (
    <div className="relative w-full h-0 pb-[56.25%]">
      <video
        ref={videoRef}
        className="absolute top-0 left-0 w-full h-full"
        controls
        src="your-video-source.mp4"
      >
        Your browser does not support the video tag.
      </video>
      <button
        className="absolute bottom-4 right-4 bg-gray-800 p-2 rounded-full text-white"
        onClick={toggleFullscreen}
      >
        {isFullscreen ? "Exit Fullscreen" : "Fullscreen"}
      </button>
    </div>
  );
}

export default VideoPlayer;

const FullscreenYouTubeVideo = ({
  url = "https://www.youtube.com/embed/yewa2-MAjFA?si=zZZ3x2dLVE3vQ5w2",
  light = "https://res.cloudinary.com/dfwvu4gct/image/upload/v1730761217/RHF/rhf_ba50hc.png",
}) => {
  const [playVid, setPlayVid] = useState(false);
  const containerRef = useRef();
  const lockRef = useRef(false);
  const theme = useTheme();

  const matchesSM = useMediaQuery(theme.breakpoints.down("sm"));
  const { isIntersecting } = useIntersectionObserver(containerRef);
  if (isIntersecting && !lockRef.current) {
    lockRef.current = true;
  }
  return (
    <div ref={containerRef} className="relative mt-4">
      {lockRef.current ? (
        <ReactPlayer
          controls
          width="100%"
          height={matchesSM ? 350 : 650}
          playing={playVid}
          playIcon={
            <PlayCircleFilledOutlined
              className="text-[#009FDE] animate-pulse"
              sx={{ fontSize: { xs: "6.5rem", md: "7.5rem" } }}
              onClick={() => setPlayVid(!playVid)}
            />
          }
          url={url}
          light={light}
        />
      ) : (
        <div className="hero-image">
          <Skeleton height={matchesSM ? 350 : 650} width="100%" />
        </div>
      )}
    </div>
  );
};

export { FullscreenYouTubeVideo };
