import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import EventItem from "./EventItem";
import EventItemReverse from "./EventItemReverse";

import { fetchEvents } from "../../../features/events/eventSlice";
import { Stack } from "@mui/material";

function CurrentEvents() {
  // fetch Current events from database
  const dispatch = useDispatch();
  const { events } = useSelector((state) => state.events);
  const currentEvents = events.filter((evt) =>
    ["Current Events", "CURRENT_EVENT"].includes(evt.category)
  );

  useEffect(() => {
    dispatch(fetchEvents());
  }, [dispatch]);

  return (
    <Stack spacing={4}>
      {currentEvents?.map(
        (
          {
            btn_url,
            id,
            list_items,
            image,
            btn_text,
            text,
            text2,
            title,
            subtitle,
            is_video,
            video_url,
            img_left,
            date,
            btn_center_under_text = null,
          },
          i
        ) =>
          img_left ? (
            <EventItem
              key={i}
              title={title}
              subtitle={subtitle}
              date={date}
              text={text}
              text2={text2}
              videoUrl={video_url}
              btnText={btn_text}
              btnUrl={btn_url}
              video={is_video}
              hasBtn={!!btn_url}
              src={image}
              listItems={list_items}
              btn_center_under_text={btn_center_under_text}
            />
          ) : (
            <EventItemReverse
              key={i}
              title={title}
              subtitle={subtitle}
              date={date}
              text={text}
              text2={text2}
              videoUrl={video_url}
              btnText={btn_text}
              btnUrl={btn_url}
              video={is_video}
              src={image}
              hasBtn={!!btn_url}
              listItems={list_items}
              btn_center_under_text={btn_center_under_text}
            />
          )
      )}
    </Stack>
  );
}

export default CurrentEvents;
