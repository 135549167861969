import React from "react";
import StyledTitle from "./StyledTitle";
import BootstrapCarouselNew from "./BootstrapCarouselNew";
import BootstrapCarousel from "../layout/BootstrapCarousel";
import { Typography } from "@mui/material";

const Pages = ({
  children,
  imageUrl,
  title = "",
  mobileSrc = null,
  useNew = false,
  subtitle = "",
}) => {
  return (
    <div>
      {useNew ? (
        <BootstrapCarouselNew images={[{ src: imageUrl, mobileSrc: mobileSrc }]} />
      ) : (
        <BootstrapCarousel images={[{ src: imageUrl, mobileSrc }]} />
      )}

      <div className="py-4 mb-4 container">
        {title !== "" && <StyledTitle title={title} />}
        {subtitle !== "" && (
          <Typography
            align="center"
            sx={{
              mb: 1,
              fontSize: "1.2rem",
            }}
          >
            {subtitle}
          </Typography>
        )}
        {children}
      </div>
    </div>
  );
};

export default Pages;
