import { Box } from "@mui/material";
import React from "react";

import { Carousel } from "react-bootstrap";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";

const BootstrapCarouselNew = ({ images }) => {
  const theme = useTheme();
  const matchesSM = useMediaQuery(theme.breakpoints.down("sm"));
  return (
    <Carousel
      indicators={images?.length === 1 ? false : true}
      controls={images?.length === 1 ? false : true}
    >
      {images?.map(({ src, name, mobileSrc = "" }, i) => (
        <Carousel.Item interval={4000} key={i}>
          <Box
            className={`xs:max-h-[400px] relative 
                 w-full sm:max-h-[670px]
              `}
          >
            <a href={matchesSM ? mobileSrc : src}>
              <img
                className={`h-[390px] w-full object-cover object-center  md:max-h-[675px]`}
                src={matchesSM ? mobileSrc : src}
                alt={name}
              />
            </a>
          </Box>
        </Carousel.Item>
      ))}
    </Carousel>
  );
};

export default BootstrapCarouselNew;
